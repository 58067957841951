import classNames from 'classnames';
import { graphql, Link } from 'gatsby';
import React, { useEffect } from 'react';
import Button from '../components/button/Button';
import Layout from '../components/layout/Layout';
import { pagePathEnum } from '../enums/pathEnum';
import * as styles from '../pages/career/career.module.scss';

interface ICareerPageData {
    markdownRemark: {
        html: string,
        frontmatter: {
            date: string
            path: string
            careerName: string
            location: string
        }
    }
}

type CareerContainerProps = {
    data: ICareerPageData
}

type CareerPageTemplateProps = {
    html: string,
    frontmatter: {
        date: string
        path: string
        careerName: string
        location: string
    }
}

export const CareerPageTemplate: React.FC<CareerPageTemplateProps> = ({ html, frontmatter }) => {
    return (
        <main className={styles.career}>
            <div className={classNames(classNames, styles.careerContainer, 'wrapper')}>
                <h1 className={styles.careerTitle}>{frontmatter.careerName}</h1>
                <span className={styles.careerLocation}>{frontmatter.location}</span>
                <div className={styles.careerDescription} dangerouslySetInnerHTML={{ __html: html }} />
                <Link className={styles.careerLink} to={pagePathEnum.APPLY_FORM}>APPLY NOW</Link>
            </div>
        </main>
    );
};

const CareerContainer: React.FC<CareerContainerProps> = ({ data }) => {
    const { markdownRemark: career } = data;
    useEffect(() => {
        localStorage.setItem('apply-for-name', `${career.frontmatter.careerName}`)
        localStorage.setItem('apply-for-location', `${career.frontmatter.location}`)
    }, [])

    return (
        <Layout>
            <CareerPageTemplate html={career.html} frontmatter={career.frontmatter} />
        </Layout>
    )
}

export default CareerContainer;

export const careerByIdQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        careerName
        location
      }
    }
  }
`